import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import Order from 'utils/orders';
import Restaurant from 'utils/restaurant';
import { ModalTypes } from 'config';
import { EDeliveryType, EDriverType, OTO_PRODUCTS } from 'enums';
import ModalContext from '../../containers/ModalContext';
import { logError } from 'utils/log';
import Shapes from 'shapes/main';
import { getCommonCallForCourrierProps } from '../deliveries/CallForCourrierForm';
import { btnLabelByProduct, driverTypeByProduct } from 'utils/deliveries';
import { IDelivery, IDeliveryCreateData } from 'types/delivery';
import { NormalizedRestaurant } from 'types/restaurants';
import { IOrder } from 'types/order';
import { addMinutes, format } from 'date-fns';
import { OtoSpinner } from '../common';

interface IProps {
  addDelivery: (
    data: IDeliveryCreateData,
    restaurant: NormalizedRestaurant
  ) => Promise<IDelivery>;
  order: IOrder;
  restaurant: NormalizedRestaurant;
}

const OrderCardCallCourrierBtn: React.FC<IProps> = (props) => {
  const { addDelivery, order, restaurant } = props;

  const [isLoading, setIsLoading] = React.useState(false);

  if (Order.isSelfCollect(order)) {
    return null;
  }

  const handleDeliveryTimeSelect = ({
    informBeforeTime,
    deliveryAtTime,
    driverType,
  }: {
    informBeforeTime: string;
    deliveryAtTime?: string;
    driverType: EDriverType;
  }) => {
    const address = Order.getAddress(order);
    const deliveryData: IDeliveryCreateData = {
      type: 'internal',
      driver_type: driverType,
      ...(deliveryAtTime
        ? { delivery_at: deliveryAtTime }
        : { inform_before: parseInt(informBeforeTime) }),
      address,
      payment_status: order.payment_type as any,
      order_id: order.id,
      phone: order.customer.phone,
      price: order.price + order.delivery_cost,
      notes: order.customer.notes || '',
      lat: order.customer.lat || '',
      lng: order.customer.lng || order.customer.long || '', //lng from web, long from mobile app
    };
    setIsLoading(true);
    addDelivery(deliveryData, restaurant)
      .catch((e) => {
        logError('call for courrier error', e);
        toast.error('Wystąpił błąd podczas wzywania kuriera');
      })
      .finally(() => setIsLoading(false));
  };

  const canOrderDriverForProduct = (product: string) => {
    if (!Restaurant.hasProduct(restaurant, product)) {
      return false;
    }
    if (product !== OTO_PRODUCTS.robot_deliveries) {
      if (order.delivery_type === EDeliveryType.ROBOT) {
        return false;
      }
    }
    if (product === OTO_PRODUCTS.robot_deliveries) {
      if (order.delivery_type === EDeliveryType.ROBOT) {
        return true;
      }
      return order?.promo_code?.code === 'dostawa-robotem-5';
    }
    return true;
  };

  return (
    <ModalContext.Consumer>
      {(modalBag) =>
        Object.entries(driverTypeByProduct).map(([product, driverType]) => (
          <React.Fragment key={product}>
            {canOrderDriverForProduct(product) && (
              <button
                key={driverType}
                type="button"
                className="btn order__btn mt-3"
                disabled={isLoading}
                onClick={(e) => {
                  e.stopPropagation();
                  const isFoodeli = restaurant.products.includes(
                    OTO_PRODUCTS.foodeli_deliveries
                  );
                  const isSzamaExpress = restaurant.products.includes(
                    OTO_PRODUCTS.szamaexpress_deliveries
                  );

                  if (isSzamaExpress) {
                    return modalBag.setModal(
                      {
                        title: 'Wzywasz kuriera SzamaExpress na zamówienie',
                        confirmText: 'Wezwij kuriera',
                        text: (
                          <>
                            <p>
                              Kurier zostanie poproszony o przyjazd 15 minut
                              przed wybranym czasem dostarczenia zamówienia do
                              klienta
                            </p>
                            <p>
                              Zamówienie przyjęte o{' '}
                              {format(
                                new Date(order.confirmed_at as string),
                                'HH:mm'
                              )}
                            </p>
                            <p>Czas realizacji: {order.prepare_time} min.</p>
                            <p>
                              Wybrany czas dostarczenia zamówienia do klienta:{' '}
                              {order.confirmed_at && order.prepare_time
                                ? format(
                                    addMinutes(
                                      new Date(order.confirmed_at),
                                      order.prepare_time
                                    ),
                                    'HH:mm'
                                  )
                                : 'Nie podano'}
                            </p>
                            {order.confirmed_at && order.prepare_time ? (
                              <p>
                                Szacowana godzina przyjazdu kuriera:{' '}
                                {format(
                                  addMinutes(
                                    new Date(order.confirmed_at),
                                    order.prepare_time - 15
                                  ),
                                  'HH:mm'
                                )}
                              </p>
                            ) : (
                              <p>
                                Szacowana godzina przyjazdu kuriera zostanie
                                wyświetlona wkrótce
                              </p>
                            )}
                          </>
                        ),
                        confirmColor: 'success',
                        confirm: () =>
                          handleDeliveryTimeSelect({
                            informBeforeTime:
                              order.prepare_time?.toString() || '',
                            deliveryAtTime: order.customer.deliveryAt || '',
                            driverType,
                          }),
                      },
                      ModalTypes.CONFIRM
                    );
                  }

                  return modalBag.setModal(
                    {
                      ...getCommonCallForCourrierProps({
                        isFoodeli,
                        isRobot: order.delivery_type === EDeliveryType.ROBOT,
                      }),
                      confirm: (
                        informBeforeTime: string,
                        deliveryAtTime?: string
                      ) => {
                        if (
                          deliveryAtTime &&
                          !/^\d{2}:\d{2}$/.test(deliveryAtTime)
                        ) {
                          toast.error(
                            'Niepoprawny format godziny. Wybierając konkretną godzinę - podaj czas dostawy do klienta w formacie HH:MM, bez dodatkowych znaków'
                          );
                          return;
                        }
                        handleDeliveryTimeSelect({
                          informBeforeTime,
                          deliveryAtTime,
                          driverType,
                        });
                      },
                    },
                    ModalTypes.SELECT
                  );
                }}
              >
                {isLoading && <OtoSpinner className="mr-2" />}
                {i18next.t(btnLabelByProduct[product])}
              </button>
            )}
          </React.Fragment>
        ))
      }
    </ModalContext.Consumer>
  );
};

OrderCardCallCourrierBtn.propTypes = {
  addDelivery: PropTypes.func.isRequired,
  order: Shapes.orderShape.isRequired,
  restaurant: Shapes.restaurantShape.isRequired,
};

export default OrderCardCallCourrierBtn;
