import { toast } from 'react-toastify';

import APIService from 'services/api';
import { PERSISTENT_LS } from 'services/persistent';
import { getArrayWithUpdatedObject } from 'utils/array';
import Logger, { logActivity, logError, stringifyEntity } from 'utils/log';
import {
  updateOrdersConfigHash,
  updateReservationConfigHash,
} from './hash-updates';
import {
  AnyRestaruant,
  NormalizedRestaurant,
  OriginalRestaurant,
  RestaurantFieldsAllowedToChange,
} from 'types/restaurants';
import { OldFormatDeliveryHour, OrdersConfig } from 'types/orders-config';
import { IAppState } from './main';
import { ITable, TableDTO } from 'types/restaurant-table';
import { IPlace, PlaceDTO, ReservationConfig } from 'types';
import { LOGOUT } from './app';

const getNormalizedRestaurant = (
  restaurant: OriginalRestaurant
): NormalizedRestaurant => {
  // Todo rootConfig needed because of bad BE structure
  const { config: rootConfig, ...restaurantWithoutConfig } = restaurant;
  // TODO fix this type
  // @ts-ignore OriginalRestaurant interface may be incorrect as there's nested config property in it
  const { config = {}, orders } = rootConfig || {};
  const normalizedRestaurant: NormalizedRestaurant = {
    ...restaurantWithoutConfig,
    config,
    orders,
    tablesConfig: [],
  };
  return normalizedRestaurant;
};

export const INITIAL_STATE: IRestaurantReducerState = {
  restaurant: null,
  error: null,
  loading: true,
  menuError: null,
  menuLoading: false,
  places: [],
  placesLoading: [],
};

export const SET_RESTAURANT_LOADING = 'SET_RESTAURANT_LOADING';
export const SET_RESTAURANT_SUCCESS = 'SET_RESTAURANT_SUCCESS';
export const SET_RESTAURANT_ERROR = 'SET_RESTAURANT_ERROR';

export const SET_RESTAURANT_MENU_LOADING = 'SET_RESTAURANT_MENU_LOADING';
export const SET_RESTAURANT_MENU_SUCCESS = 'SET_RESTAURANT_MENU_SUCCESS';
export const SET_RESTAURANT_MENU_ERROR = 'SET_RESTAURANT_MENU_ERROR';

export const UPDATE_RESTAURANT_CONFIG = 'UPDATE_RESTAURANT_CONFIG';
export const UPDATE_RESTAURANT_COORDS = 'UPDATE_RESTAURANT_COORDS';
export const UPDATE_RESTAURANT_MENU = 'UPDATE_RESTAURANT_MENU';
export const UPDATE_RESTAURANT_OPENING_HOURS =
  'UPDATE_RESTAURANT_OPENING_HOURS';

export const ADD_RESTAURANT_PLACE = 'ADD_RESTAURANT_PLACE';
export const SET_RESTAURANT_PLACES = 'SET_RESTAURANT_PLACES';
export const CLEAR_RESTAURANT_PLACES = 'CLEAR_RESTAURANT_PLACES';

export const UPDATE_RESTAURANT_PLACE_REQUESTED =
  'UPDATE_RESTAURANT_PLACE_REQUESTED';
export const UPDATE_RESTAURANT_PLACE_SUCCEEDED =
  'UPDATE_RESTAURANT_PLACE_SUCCEEDED';
export const UPDATE_RESTAURANT_PLACE_FAILED = 'UPDATE_RESTAURANT_PLACE_FAILED';

export const ADD_RESTAURANT_TABLE = 'ADD_RESTAURANT_TABLE';
export const UPDATE_RESTAURANT_TABLE = 'UPDATE_RESTAURANT_TABLE';

export interface IRestaurantReducerState {
  restaurant: NormalizedRestaurant | null;
  error: any;
  loading: boolean;
  menuError: any;
  menuLoading: boolean;
  places: IPlace[];
  placesLoading: number[];
}

function restaurantReducer(state = INITIAL_STATE, action: any) {
  const tablesConfig = (state.restaurant as any)?.tablesConfig || [];

  switch (action.type) {
    case SET_RESTAURANT_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SET_RESTAURANT_SUCCESS:
      return {
        ...state,
        restaurant: action.restaurant,
        loading: false,
        error: null,
      };
    case UPDATE_RESTAURANT_CONFIG:
      const updatedRestaurant = {
        ...state.restaurant,
        config: action.config,
        orders: action.orders,
      };
      if (!action.orders) {
        logActivity(
          'Restaurant orders config was reset ' + stringifyEntity(action),
          { instant: true }
        );
      }
      return {
        ...state,
        restaurant: updatedRestaurant,
      };
    case SET_RESTAURANT_MENU_LOADING:
      return {
        ...state,
        menuLoading: true,
        menuError: null,
      };
    case SET_RESTAURANT_MENU_SUCCESS:
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          menu: action.menu,
        },
        menuLoading: false,
        menuError: null,
      };
    case SET_RESTAURANT_MENU_ERROR:
      return {
        ...state,
        menuLoading: false,
        menuError: action.error,
      };
    case UPDATE_RESTAURANT_COORDS:
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          ...action.payload,
        },
      };
    case UPDATE_RESTAURANT_MENU:
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          [action.menuKey]: action.menuData,
        },
      };
    case ADD_RESTAURANT_PLACE:
      return {
        ...state,
        places: [...state.places, action.payload],
      };
    case UPDATE_RESTAURANT_PLACE_REQUESTED:
      return {
        ...state,
        placesLoading: [...state.placesLoading, action.payload.id],
      };
    case UPDATE_RESTAURANT_PLACE_SUCCEEDED:
      return {
        ...state,
        places: getArrayWithUpdatedObject(state.places, action.payload),
        placesLoading: state.placesLoading.filter(
          (id) => id !== action.payload.id
        ),
      };
    case UPDATE_RESTAURANT_PLACE_FAILED:
      return {
        ...state,
        placesLoading: state.placesLoading.filter(
          (id) => id !== action.payload.id
        ),
      };

    case SET_RESTAURANT_PLACES:
      return {
        ...state,
        places: action.payload,
      };
    case CLEAR_RESTAURANT_PLACES:
    case ADD_RESTAURANT_TABLE:
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          tablesConfig: [...tablesConfig, action.payload],
        },
      };
    case UPDATE_RESTAURANT_TABLE:
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          tablesConfig: getArrayWithUpdatedObject(tablesConfig, action.payload),
        },
      };
    case SET_RESTAURANT_ERROR:
      return {
        ...state,
        restaurant: {},
        loading: false,
        error: action.error,
      };

    case UPDATE_RESTAURANT_OPENING_HOURS:
      return {
        ...state,
        restaurant: {
          ...state.restaurant,
          ...action.payload,
        },
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export const setRestaurantLoading = () => ({ type: SET_RESTAURANT_LOADING });
export const setRestaurantSuccess = (restaurant) => ({
  type: SET_RESTAURANT_SUCCESS,
  restaurant,
});
export const setRestaurantError = (error) => ({
  type: SET_RESTAURANT_ERROR,
  error,
});

const hasNormalizedConfig = (restaurant) =>
  restaurant.config &&
  restaurant.orders &&
  (!restaurant.config.flexible_tables || restaurant.tablesConfig);

export const loadRestaurant =
  (restaurantId: number, options = { allowCache: true, setLoading: true }) =>
  (dispatch) => {
    const { allowCache = true, setLoading = true } = options;
    setLoading && dispatch({ type: SET_RESTAURANT_LOADING });
    const lsRestaurant = PERSISTENT_LS.getRestaurant();
    if (
      allowCache &&
      !!lsRestaurant &&
      hasNormalizedConfig(lsRestaurant) &&
      restaurantId === lsRestaurant.id
    ) {
      // TODO probably check & reload restaurant once per day or so to make sure updated config present
      return dispatch(setRestaurantSuccess(lsRestaurant));
    }
    const url = `/restaurants/${restaurantId}?include=config`;
    return APIService.get(url)
      .then((restaurant) => {
        const normalizedRestaurant = getNormalizedRestaurant(restaurant);
        if (normalizedRestaurant.config.flexible_tables) {
          const tablesUrl = `/restaurants/${restaurant.id}/tables?with=place`;
          return APIService.get(tablesUrl)
            .then((tablesConfig) => {
              const normalizedRestaurantWithTables = {
                ...normalizedRestaurant,
                tablesConfig,
              };
              PERSISTENT_LS.setRestaurant(normalizedRestaurantWithTables);
              return dispatch(
                setRestaurantSuccess(normalizedRestaurantWithTables)
              );
            })
            .catch((e) => {
              Logger.fetchError({ e, url: tablesUrl, method: 'GET' });
              toast.error('Problem pobierania stolików i sal restauracji');
              return dispatch(setRestaurantError(e));
            });
        }
        PERSISTENT_LS.setRestaurant(normalizedRestaurant);
        dispatch(setRestaurantSuccess(normalizedRestaurant));
        return { restaurant: normalizedRestaurant };
      })
      .catch((e) => {
        Logger.fetchError({ e, url, method: 'GET' });
        toast.error('Problem pobierania danych restauracji');
        return dispatch(setRestaurantError(e));
      });
  };

export const loadRestaurantPlaces =
  (restaurant: AnyRestaruant) => (dispatch) => {
    const url = `/restaurants/${restaurant.id}/places`;
    APIService.get(url)
      .then((places: IPlace[]) => {
        dispatch({
          type: SET_RESTAURANT_PLACES,
          payload: places,
        });
        return places;
      })
      .catch((e) => {
        toast.error('Błąd pobierania sal');
        Logger.fetchError({ e, url, method: 'GET' });
      });
  };

export const clearRestaurantPlaces = () => (dispatch) => {
  dispatch({ type: CLEAR_RESTAURANT_PLACES });
};

export const loadRestaurantMenu = (restaurant: AnyRestaruant) => (dispatch) => {
  dispatch({ type: SET_RESTAURANT_MENU_LOADING });
  const url = `/restaurants/${restaurant.id}/menu?inlineSets=false`;
  return APIService.get(url)
    .then((menu) => {
      dispatch({
        type: SET_RESTAURANT_MENU_SUCCESS,
        menu,
      });
      return menu;
    })
    .catch((e) => {
      Logger.fetchError({ e, url, method: 'GET' });
      toast.error(
        'Wystąpił błąd odświeżenia menu. Możesz edytować menu, ale istnieje ryzyko jego nadpisania, jeżeli było ono edytowane przez inną osobę.'
      );
      return dispatch({
        type: SET_RESTAURANT_MENU_ERROR,
        error: e,
      });
    });
};

type TConfigUpdateResponse = {
  config: ReservationConfig;
  created_at: string;
  delivery_hours: OldFormatDeliveryHour[];
  id: number;
  orders: OrdersConfig;
  restaurant: OriginalRestaurant; // TODO makes no sense, this instance has config already, with config.config & config.orders
  restaurant_id: number;
  today_delivery_hours: OldFormatDeliveryHour[];
  updated_at: string;
};

export const updateReservationsConfig =
  (
    payload: {
      config: ReservationConfig;
    },
    restaurant: NormalizedRestaurant,
    onSuccess?: (newConfig: TConfigUpdateResponse) => void
  ) =>
  (dispatch) => {
    return (
      APIService.put(
        `/restaurants/${restaurant.id}/config/reservations`,
        payload
      )
        // Todo newRootConfig needed because of bad BE structure
        .then((newRootConfig) => {
          const { config, orders } = newRootConfig;
          onSuccess && onSuccess(newRootConfig);
          PERSISTENT_LS.updateLsRestaurant({ config, orders });
          dispatch(updateReservationConfigHash(restaurant));
          return dispatch({
            type: UPDATE_RESTAURANT_CONFIG,
            config,
            orders,
          });
        })
        .catch((e) => {
          toast.error('Wystąpił problem podczas zapisu ustawień rezerwacji.');
          logError('Reservations settings save fail', e);
        })
    );
  };

export const updateOrdersConfig =
  (
    payload: Partial<OrdersConfig>,
    restaurant: NormalizedRestaurant,
    onSuccess?: (newConfig: TConfigUpdateResponse) => void
  ) =>
  async (dispatch) => {
    return (
      APIService.put(`/restaurants/${restaurant.id}/config/orders`, payload)
        // Todo newRootConfig needed because of bad BE structure
        .then((newRootConfig) => {
          const { config, orders } = newRootConfig;
          onSuccess && onSuccess(newRootConfig);
          PERSISTENT_LS.updateLsRestaurant({ config, orders });
          dispatch(updateOrdersConfigHash(restaurant));
          dispatch({
            type: UPDATE_RESTAURANT_CONFIG,
            config,
            orders,
          });
          return Promise.resolve(newRootConfig);
        })
        .catch((e) => {
          toast.error('Wystąpił problem podczas zapisu ustawień zamówień.');
          logError('Orders settings save fail', e);
          return Promise.reject(e);
        })
    );
  };

export const updateRestaurant =
  ({
    id,
    payload,
    onSuccess,
    onError,
  }: {
    id: number;
    payload: Partial<RestaurantFieldsAllowedToChange>;
    onSuccess?: (restaurant: NormalizedRestaurant) => void;
    onError?: (e: any) => void;
  }) =>
  (dispatch) => {
    const url = `/restaurants/${id}`;
    return APIService.put(url, payload)
      .then((updatedRestaurant: NormalizedRestaurant) => {
        toast.success('Restauracja została sukcesywnie zaktualizowana');
        onSuccess && onSuccess(updatedRestaurant);
        PERSISTENT_LS.updateLsRestaurant({
          lat: updatedRestaurant.lat,
          lng: updatedRestaurant.lng,
        });
        return dispatch({
          type: UPDATE_RESTAURANT_COORDS,
          payload: {
            lat: updatedRestaurant.lat,
            lng: updatedRestaurant.lng,
          },
        });
      })
      .catch((e) => {
        toast.error('Błąd aktualizacji restauracji');
        logError(`${url} updateRestaurant fail`, e);
        onError && onError(e);
      });
  };

export const updateRestaurantMenu =
  (
    id: number,
    payload: Partial<NormalizedRestaurant>,
    {
      menuKey = 'menu',
      onSuccess,
      onError,
    }: {
      menuKey: 'menu' | 'menu_on_place';
      onSuccess?: (restaurant: NormalizedRestaurant) => void;
      onError?: (e: any) => void;
    }
  ) =>
  (dispatch) => {
    const url = `/restaurants/${id}`;
    return APIService.put(url, payload)
      .then((updatedRestaurant: NormalizedRestaurant) => {
        toast.success('Menu zostało sukcesywnie zaktualizowane');
        onSuccess && onSuccess(updatedRestaurant);
        PERSISTENT_LS.updateLsRestaurant({
          [menuKey]: updatedRestaurant[menuKey],
        });
        return dispatch({
          type: UPDATE_RESTAURANT_MENU,
          menuKey,
          menuData: updatedRestaurant[menuKey],
        });
      })
      .catch((e) => {
        toast.error('Problem zapisu menu');
        logError(`${url} updateRestaurantMenu fail`, e);
        onError && onError(e);
      });
  };

export const addTable =
  (restaurant: NormalizedRestaurant, tableData: TableDTO) => (dispatch) => {
    const url = `/restaurants/${restaurant.id}/tables?with=place`;
    APIService.post(url, tableData)
      .then((table: ITable) => {
        PERSISTENT_LS.addLsRestaurantTable(table);
        dispatch({
          type: ADD_RESTAURANT_TABLE,
          payload: table,
        });
        toast.success(`Stolik ${tableData.name} został dodany`);
        return table;
      })
      .catch((e) => {
        toast.error('Błąd dodawania stolika');
        logError('Error restaurant table create', e);
      });
  };

export const updateRestaurantTable =
  (restaurant: AnyRestaruant, tableData: ITable) => (dispatch) => {
    const url = `/restaurants/${restaurant.id}/tables/${tableData.id}?with=place`;
    return APIService.put(url, tableData)
      .then((updatedTable) => {
        PERSISTENT_LS.updateLsRestaurantTable(updatedTable);
        dispatch({
          type: UPDATE_RESTAURANT_TABLE,
          payload: updatedTable,
        });
        toast.success(`Stolik ${updatedTable.name} został zaktualizowany`);
        return updatedTable;
      })
      .catch((e) => {
        toast.error('Błąd aktualizacji stolika');
        logError('Error restaurant table update', e);
      });
  };

export const addPlace =
  (restaurant: AnyRestaruant, placeData: PlaceDTO) => (dispatch) => {
    const url = `/restaurants/${restaurant.id}/places`;
    return APIService.post(url, placeData)
      .then((newPlace: IPlace) => {
        toast.success(`Sala ${placeData.name} została dodana`);
        dispatch({
          type: ADD_RESTAURANT_PLACE,
          payload: newPlace,
        });
        return newPlace;
      })
      .catch(() => toast.error('Błąd dodawania sali'));
  };

export const updateRestaurantPlace =
  (restaurant: AnyRestaruant, payload: IPlace) => (dispatch) => {
    const url = `/restaurants/${restaurant.id}/places/${payload.id}`;
    dispatch({ type: UPDATE_RESTAURANT_PLACE_REQUESTED, payload: payload });
    return APIService.put(url, payload)
      .then((updatedPlace: IPlace) => {
        dispatch({
          type: UPDATE_RESTAURANT_PLACE_SUCCEEDED,
          payload: updatedPlace,
        });
        toast.success(`Sala ${updatedPlace.name} została zaktualizowana`);
        return updatedPlace;
      })
      .catch((e) => {
        dispatch({ type: UPDATE_RESTAURANT_PLACE_FAILED, payload });
        toast.error('Błąd aktualizacji sali');
        Logger.fetchError({
          e,
          url,
          method: 'PUT',
          payload: payload as unknown as Record<string, unknown>,
        });
      });
  };

export const updateRestaurantOpeningHours =
  (payload, id, onSuccess) => (dispatch) => {
    const url = `/restaurants/${id}/opening-hours`;
    return APIService.put(url, payload)
      .then((newHours) => {
        onSuccess && onSuccess(newHours);
        PERSISTENT_LS.updateLsRestaurant(newHours);
        return dispatch({
          type: UPDATE_RESTAURANT_OPENING_HOURS,
          payload: newHours,
        });
      })
      .catch((e) => {
        toast.error('Wystąpił błąd podczas zapisu godzin restauracji');
        Logger.fetchError({ e, url, method: 'PUT', payload });
      });
  };

export const selectRestaurant = (
  state: IAppState
): NormalizedRestaurant | null => state.restaurant.restaurant;

export const selectRestaurantLoading = (state: IAppState): boolean =>
  state.restaurant.loading;

export const selectRestaurantTables = (state: IAppState): ITable[] =>
  selectRestaurant(state)?.tablesConfig || [];

export const selectBookingConfig = (state: IAppState): ReservationConfig =>
  selectRestaurant(state)?.config as ReservationConfig;

export const selectOrdersConfig = (state: IAppState): OrdersConfig | null =>
  selectRestaurant(state)?.orders || null;

export const selectPlaces = (state: IAppState): IPlace[] =>
  state.restaurant.places;

export const selectPlacesLoading = (state: IAppState): number[] =>
  state.restaurant.placesLoading;

export default restaurantReducer;
