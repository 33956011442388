import filterXss from 'xss';

import { throttle } from './general';
import { getRestaurant } from 'services/persistent';
import { isProd, API_URL } from 'config';
import { releaseVersion, version } from 'globals/version';
import { store } from 'store/index';
import type { User } from 'types';

const ACTIVITY_TYPE = 'activity';
const SALES_ACTIVITY_TYPE = 'sales_activity';
const ERROR_TYPE = 'error';

const MAX_LOG_FREQUENCY = 3000;

let activityLogMessage = '';
let errorLogMessage = '';

let actionsLog = '';

export interface ILogOptions {
  instant?: boolean;
}

const throttledLogActivity = throttle(() => {
  logGeneral(activityLogMessage, ACTIVITY_TYPE);
  activityLogMessage = '';
}, MAX_LOG_FREQUENCY);

const throttledLogError = throttle(() => {
  logGeneral(errorLogMessage, ERROR_TYPE);
  errorLogMessage = '';
}, MAX_LOG_FREQUENCY);

export function logActivity(
  message: string,
  options: ILogOptions = { instant: false }
) {
  if (options && options.instant) {
    logGeneral(message, ACTIVITY_TYPE);
  } else {
    activityLogMessage += message + '\n';
    throttledLogActivity();
  }
}

export function logSalesActivity(message: string) {
  return logGeneral(message, SALES_ACTIVITY_TYPE);
}

export const sergMention = '<@ULSG2BVPW>';
export const stasMention = '<@ULV4W7JR4>';

export function logImportantActivity(message: string, options?: ILogOptions) {
  return logActivity(`${stasMention} ${message}`, options);
}

export const userFriednlyLog = (data) =>
  Object.entries(data)
    .map(([key, value]) => `${key}: ${value}`)
    .join('\n');

const shouldBeFilteredOut = (text: string, error: any): boolean => {
  const restaurant = getRestaurant();
  if (restaurant?.name) {
    if (
      restaurant?.name.includes('Zozan') ||
      restaurant?.name.includes('Nefis')
    ) {
      if (
        text.includes('The node to be removed is not a child of this node.') ||
        text.includes(
          'The node before which the new node is to be inserted is not a child of this node.'
        )
      ) {
        return true;
      }
    }
  }
  return false;
};

export function logError(
  text: string,
  error = {},
  options: ILogOptions = { instant: false }
) {
  if (shouldBeFilteredOut(text, error)) {
    return;
  }
  const message = `${text} ${stringifyEntity(error)}`;
  if (options && options.instant) {
    logGeneral(message, ERROR_TYPE);
  } else {
    errorLogMessage += message + '\n';
    throttledLogError();
  }
}

function logGeneral(text: string, type = ERROR_TYPE) {
  const { id, name } = getRestaurant() || {};
  let user: User | undefined;

  try {
    user = store.getState().app.user;
  } catch (e) {
    console.log('user store is not available', e);
  }

  const isError = type === ERROR_TYPE;

  const restaurantPart = id && name ? `for ${id} ${name}` : 'No Restaurant';
  const userPart = user
    ? `User: ${user.name} ${user.email} (${user.id})`
    : 'No User';
  const urlPart = `URL: ${filterXss(window.location.href)}`;
  const versionPart = `Version: ${releaseVersion}`;

  const message = [
    'Admin panel',
    text,
    restaurantPart,
    userPart,
    urlPart,
    versionPart,
  ]
    .filter(Boolean)
    .join('\n');

  if (!isProd) {
    isError ? console.error(message) : console.warn(message);
    return;
  }

  fetch(`${API_URL}/logger/js`, {
    method: 'POST',
    body: JSON.stringify({
      message,
      type,
    }),
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      'x-app-version': version,
    },
  });
}

const TELEGRAM_SAFE_LEN = 2000;

export function stringifyEntity(entity: any) {
  if (!entity) {
    return 'NO_ENTITY';
  }
  if (typeof entity === 'string') {
    return entity;
  }
  if (entity instanceof Error) {
    const ownPropertiesStr = JSON.stringify(
      entity,
      Object.getOwnPropertyNames(entity)
    ).slice(0, TELEGRAM_SAFE_LEN);
    // @ts-ignore smth wrong with types here, but sometimes entity can have status
    return `${ownPropertiesStr}; status: ${entity?.status}; message: ${entity?.message}`;
  }
  if (typeof entity === 'object') {
    return `${JSON.stringify(entity).slice(0, TELEGRAM_SAFE_LEN)}; status: ${
      entity.status
    }`;
  }
  return 'OTHER_ENTITY';
}

export function formatChangedKeys(keysThatChanged: string[], oldDict, newDict) {
  const getLineFromKey = (key: string) => {
    if (typeof oldDict[key] === 'object') {
      return `${key}: ${JSON.stringify(oldDict[key])} -> ${JSON.stringify(
        newDict[key]
      )}`;
    }
    return `${key}: ${oldDict[key]} -> ${newDict[key]}`;
  };
  return `Changed keys: \n${keysThatChanged.map(getLineFromKey).join('\n')}`;
}

export function createDerivedStateFromErrorLogger(componentName: string) {
  return (error) => {
    logError(
      `${componentName} caught error in derived state.
      URL: ${filterXss(window.location.pathname)}`,
      error
    );
    return { hasError: true };
  };
}

export function logComponentDidCatch(name: string, error: any, info: any) {
  // const { componentStack = 'unknownStack' } = info;
  return logError(`${name} DidCatch. ${stringifyEntity(error)}\n`);
}

export function logIfMemoryLeak(msg, url, lineNo, columnNo, error) {
  const isPotentialMemoryLeak = msg && msg.includes && msg.includes('memory');
  if (isPotentialMemoryLeak) {
    logError(
      `General window.onerror:
      msg: ${msg}
      url: ${url}
      lineNo: ${lineNo}
      columnNo: ${columnNo}`,
      error,
      { instant: true }
    );
  }
}

const errorMessagesToSkip = [
  'NetworkError when attempting to fetch resource.',
  'Połączenie sieciowe zostało utracone.',
  'Przekroczenie limitu czasu żądania.',
  'Failed to fetch',
  'Load failed',
  'anulowane',
  'cancelled',
];

type TFetchErrorLogParams =
  | {
      e: any;
      url: string;
      actionName?: string;
      method: 'GET' | 'DELETE';
    }
  | {
      e: any;
      url: string;
      actionName?: string;
      method: 'POST' | 'PUT';
      payload: Record<string, unknown>;
    };

class LoggerFactory {
  skippableErrorsDict = {};

  removeComplexStructureFromPayload = (
    payload: Record<string, unknown>
  ): Record<string, unknown> => {
    if (!payload) {
      return {};
    }
    const payloadCopy = { ...payload };
    // check each key in payload
    for (const key in payloadCopy) {
      if (typeof payloadCopy[key] === 'object') {
        payloadCopy[key] = '...';
      }
    }
    return payloadCopy;
  };

  fetchError = (params: TFetchErrorLogParams) => {
    const { actionName, e, url, method } = params;
    if (!e.status && errorMessagesToSkip.includes(e.message)) {
      if (e.message === 'Failed to fetch') {
        return;
      }
      if (!this.skippableErrorsDict[e.message]) {
        this.skippableErrorsDict[e.message] = 0;
      }
      this.skippableErrorsDict[e.message]++;
      if (this.skippableErrorsDict[e.message] >= 4) {
        logError(
          `Skippable error occured ${this.skippableErrorsDict[e.message]} TIMES.
          Error type: ${e.message}
          Endpoint: ${method} ${url}.`,
          e
        );
        this.skippableErrorsDict[e.message] = 0;
      }
    } else {
      const PAYLOAD_MAX_LENGTH = 500; // to avoid failing in case of huge payload
      const payloadErrorPart: string =
        'payload' in params
          ? ` Payload: ${JSON.stringify(
              this.removeComplexStructureFromPayload(params.payload)
            ).slice(0, PAYLOAD_MAX_LENGTH)}`
          : '';
      logError(
        `Other error during ${method} ${url} fetch.${payloadErrorPart}`,
        e
      );
    }
  };
}

const Logger = new LoggerFactory();

export const LOG_BAG = {
  actionsLog,
  createDerivedStateFromErrorLogger,
  logComponentDidCatch,
  logError,
  logActivity,
  logSalesActivity,
  addLogAction: (msg: string) => {
    actionsLog = `${msg}\n${actionsLog};`;
  },
  logImportantActivity,
  mentions: {
    stas: stasMention,
    serg: sergMention,
  },
};

export default Logger;
