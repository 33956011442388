import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { setReservations, setReservationsLoading } from 'store/reservations';
import { isReservationsConfigFinished } from 'utils/reservation';
import { hasReservations } from 'utils/restaurant';
import APIService from 'services/api';
import { NormalizedRestaurant } from 'types/restaurants';
import Logger from 'utils/log';
import { ReservationConfig } from 'types';
import { toast } from 'react-toastify';
import i18next from 'i18next';

export interface ReservationsFetcherProps {
  config: ReservationConfig;
  daysLimitFilter?: string;
  includeTablesAndTheirPlaces: boolean;
  frequency: number;
  requireReservationsProduct?: boolean;
  restaurant: NormalizedRestaurant;
  showToastOnError?: boolean;
}

type TProps = ReservationsFetcherProps & PropsFromRedux;

class ReservationsFetcher extends React.Component<TProps, {}> {
  fetchInterval: NodeJS.Timeout | null = null;

  componentDidMount() {
    this.updateReservations();
    this.resetInterval();
  }

  componentDidUpdate(prevProps: TProps): void {
    if (
      this.props.daysLimitFilter !== prevProps.daysLimitFilter ||
      this.props.restaurant.id !== prevProps.restaurant.id
    ) {
      this.updateReservations();
      this.resetInterval();
    }
  }

  componentWillUnmount() {
    this.fetchInterval && clearInterval(this.fetchInterval);
  }

  resetInterval = () => {
    this.fetchInterval && clearInterval(this.fetchInterval);
    this.fetchInterval = setInterval(
      this.updateReservations,
      this.props.frequency
    );
  };

  updateReservations = () => {
    const { config, requireReservationsProduct, restaurant } = this.props;
    if (
      !isReservationsConfigFinished(config) ||
      (requireReservationsProduct && !hasReservations(restaurant))
    ) {
      return;
    }
    const { includeTablesAndTheirPlaces, daysLimitFilter } = this.props;

    const statusPart = '?filter[status]=confirmed|declined';
    const withPart = includeTablesAndTheirPlaces ? '&include=tables.place' : '';
    const daysLimitPart = daysLimitFilter
      ? `&filter[reserved_after]=${daysLimitFilter}`
      : '';

    const url = `/restaurants/${restaurant.id}/reservations${statusPart}${withPart}${daysLimitPart}`;
    this.props.setReservationsLoading();

    APIService.get(url)
      .then(this.props.setReservations)
      .catch((e) => {
        if (this.props.showToastOnError) {
          toast.error(i18next.t('fetch-error.get.reservations'));
        }
        return Logger.fetchError({ e, url, method: 'GET' });
      });
  };

  render() {
    return null;
  }
}

const mapDispatchToProps = {
  setReservations,
  setReservationsLoading,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ReservationsFetcher);
