import { toast } from 'react-toastify';
import {
  ISavedReservation,
  ParamsReservationEdit,
  CreateReservationDTO,
  ReservationStatus,
  ReservationSource,
} from 'types/reservation';
import { getArrayWithUpdatedObject } from 'utils/array';
import Logger, { LOG_BAG } from 'utils/log';
import APIService from 'services/api';
import { CHANGE_RESTAURANT, LOGOUT } from './app';
import { IAppState } from './main';

export const INITIAL_STATE: IReservationsReducerState = {
  reservations: [],
  reservationsLoading: false,
  reservationsError: false,
};

export const ADD_RESERVATION = 'reservations/ADD_RESERVATION';
const UPDATE_RESERVATION = 'UPDATE_RESERVATIONreservations/';
const DECLINE_RESERVATION = 'DECLINE_RESERVATIONreservations/';
export const SET_RESERVATIONS_LOADING = 'reservations/SET_RESERVATIONS_LOADING_LOADING';
export const SET_RESERVATIONS_SUCCESS = 'reservations/SET_RESERVATIONS_SUCCESS';
export const SET_RESERVATIONS_ERROR = 'reservations/SET_RESERVATIONS_ERROR';

export interface IReservationsReducerState {
  reservations: ISavedReservation[];
  reservationsLoading: boolean;
  reservationsError: boolean;
}

interface IReservationsAction {
  type: string;
  reservation: ISavedReservation; // TODO optional
  reservations: ISavedReservation[]; // TODO optional
  error: boolean; // TODO make optional and fix type
}

function reservationsReducer(
  state = INITIAL_STATE,
  action: IReservationsAction
): IReservationsReducerState {
  switch (action.type) {
    case SET_RESERVATIONS_LOADING:
      return {
        ...state,
        reservationsLoading: true,
        reservationsError: false,
      };
    case SET_RESERVATIONS_SUCCESS:
      return {
        ...state,
        reservations: action.reservations,
        reservationsLoading: false,
        reservationsError: false,
      };
    case SET_RESERVATIONS_ERROR:
      return {
        ...state,
        reservations: [],
        reservationsLoading: false,
        reservationsError: action.error,
      };
    case DECLINE_RESERVATION:
      return {
        ...state,
        reservations: state.reservations.map((item) =>
          item.id === action.reservation.id
            ? {
                ...item,
                ...action.reservation,
                status: ReservationStatus.declined,
              }
            : item
        ),
      };
    case ADD_RESERVATION:
      return {
        ...state,
        reservations: [...state.reservations, action.reservation],
      };
    case UPDATE_RESERVATION:
      return {
        ...state,
        reservations: getArrayWithUpdatedObject(
          state.reservations,
          action.reservation
        ),
      };
    case LOGOUT:
    case CHANGE_RESTAURANT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export const setReservationsLoading = () => ({
  type: SET_RESERVATIONS_LOADING,
});
export const setReservations = (reservations: ISavedReservation[]) => ({
  type: SET_RESERVATIONS_SUCCESS,
  reservations,
});

export const addReservation =
  (payload: CreateReservationDTO) => (dispatch) => {
    const url = '/reservations';
    return APIService.post(url, payload)
      .then((reservation: ISavedReservation) => {
        toast.success('Rezerwacja została pomyślnie zapisana');
        dispatch({
          type: ADD_RESERVATION,
          reservation,
        });
        return reservation;
      })
      .catch((e) => {
        const toastMessage =
          e?.message === 'Phone number is not correct'
            ? 'Błąd zapisu rezerwacji - podano błędny numer telefonu. Jeżeli nie masz poprawnego numeru  - podaj inny nr komórkowy, np nr do restauracji, lub prywatny.'
            : `Wystąpił problem podczas zapisu rezerwacji. ${e?.message || ''}`;
        Logger.fetchError({ e, url, method: 'POST', payload });
        toast.error(toastMessage, { autoClose: false });
      });
  };

export const updateReservation =
  (reservation: ISavedReservation, values: Partial<ParamsReservationEdit>) =>
  (dispatch) => {
    const url = `/reservations/${reservation.id}?include=tables.place`;
    const payload: Partial<ParamsReservationEdit> = {
      ...values,
      source: values.source || ReservationSource.dashboard,
    }
    return APIService.put(url, payload)
      .then((updatedReservation: ISavedReservation) => {
        toast.success('Rezerwacja została pomyślnie zaktualizowana');
        dispatch({
          type: UPDATE_RESERVATION,
          reservation: updatedReservation,
        });
        return updatedReservation;
      })
      .catch((e) => {
        Logger.fetchError({ e, url, method: 'PUT', payload });
        toast.error('Błąd zapisu rezerwacji!', {
          autoClose: false,
        });
        return null;
      });
  };

export const declineReservation =
  (params: {
    reservation: ISavedReservation;
    successMessage: string;
    errorMessage: string;
    declineCode: number;
  }) =>
  (dispatch) => {
    const { reservation, errorMessage, successMessage, declineCode } = params;
    if (!reservation.code) {
      LOG_BAG.logError('Reservation without code: ', reservation);
    }

    const payload = {
      declined_reason_id: declineCode,
    };

    const url = `/reservations/${reservation.code}`;
    return APIService.del(url, payload)
      .then(() => {
        toast.success(successMessage);
        dispatch({
          type: DECLINE_RESERVATION,
          reservation: {
            ...reservation,
            ...payload,
          },
        });
        return true;
      })
      .catch((e) => {
        Logger.fetchError({ e, url, method: 'DELETE' });
        toast.error(errorMessage, {
          autoClose: false,
        });
        return false;
      });
  };

export default reservationsReducer;

export const selectReservations = (state: IAppState) => state.reservations.reservations;
export const selectReservationsLoading = (state: IAppState) => state.reservations.reservationsLoading;