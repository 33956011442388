import React from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { toast, ToastId } from 'react-toastify';
import { Row } from 'reactstrap';

import { checkAppVersion, checkToken } from 'services/auth';
import { FREQUENCY, ModalTypes, ORDER_STATUS, VIEWS } from 'config';
import ModalContext from '../containers/ModalContext';
import OrdersFetcher from '../containers/OrdersFetcher';
import PendingOrdersContainer from '../containers/PendingOrdersContainer';
import OrdersList from 'app/components/orders/OrdersList';
import ViewOrderModal from 'app/views/ViewOrderModal';
import OrderCard from 'app/components/orders/OrderCard';
import OtoSpinner from 'app/components/common/OtoSpinner';
import OrdersDeliveryTimeAlert from './OrdersDeliveryTimeAlert';
import CheckDeliveryPrice from 'app/components/orders/CheckDeliveryPrice';
import DisableOrdersToggle from 'app/components/orders/DisableOrdersToggle';
import { addDelivery } from 'store/deliveries';
import {
  closeOrderModal,
  openOrderModal,
  selectAllOrders,
  selectOpenedOrder,
  selectOrdersLoading,
  selectUpdatingOrders,
  setOrdersError,
  setOrdersSuccess,
  updateOrder,
  updateOrderStatus,
} from 'store/orders';
import {
  selectBookingConfig,
  selectOrdersConfig,
  selectRestaurant,
  updateOrdersConfig,
} from 'store/restaurant';
import { UpdateTarget } from 'enums';
import Logger, { LOG_BAG } from 'utils/log';
import { isToday, newSafeDate } from 'utils/date-time';
import Order, {
  isDeclined,
  isDelivered,
  isNotNew,
  isOrdersConfigFinished,
} from 'utils/orders';
import withSetModal, { TSetModalFunction } from '../containers/WithSetModal';
import DeliveriesFetcher from '../containers/DeliveriesFetcher';
import ViewToggler from 'app/components/common/ViewToggler';
import { PERSISTENT_LS } from 'services/persistent';
import PreviewLink from 'app/views/ViewPreviewLink';
import { selectUserRestaurants } from 'store/app';
import OrdersConfigNotFinished from 'app/components/alerts/OrdersConfigNotFinishedAlert';
import UpdatesProvider from '../containers/UpdatesProvider';
import ReservationsFetcher from '../containers/ReservationsFetcher';
import { IOrder, NormalizedRestaurant, OrdersConfig } from 'types';
import { PageComponentProps } from '../types-global';
import { IAppState } from 'store/main';
import RestaurantIsNotLiveAlert from 'app/components/alerts/RestaurantIsNotLiveAlert';
import ViewOrderCourrierDetails from 'app/views/ViewOrderCourrierDetails';
import { OrderSource } from 'app/components/orders/order-parts/OrderSource';

import './OrdersListPage.scss';

const COMPONENT_NAME = 'OrdersListPage';

type TOWnProps = {
  // config: ReservationConfig;
  // ordersConfig: OrdersConfig;
};

type TSetModalProps = {
  setModal: TSetModalFunction;
};

type TProps = TOWnProps & PropsFromRedux & TSetModalProps;

type TState = {
  loading: boolean;
  view: {
    text: string;
    icon: React.ReactNode;
  };
};

class OrdersListPage extends React.PureComponent<TProps, TState> {
  _isMounted = false;

  reloadInterval: number | null = null;
  errorToastId: ToastId | null = null;

  constructor(props: TProps) {
    super(props);
    this.checkUpToDateVersion();
    if (checkToken()) {
      this.state = {
        loading: true,
        view: VIEWS[PERSISTENT_LS.getOrdersView()],
      };
    }
  }

  static displayName = COMPONENT_NAME;

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidCatch(error, info) {
    LOG_BAG.logComponentDidCatch(COMPONENT_NAME, error, info);
    this.showGeneralToastError();
  }

  static getDerivedStateFromError =
    LOG_BAG.createDerivedStateFromErrorLogger(COMPONENT_NAME);

  componentWillUnmount() {
    this.reloadInterval && clearInterval(this.reloadInterval);
    this._isMounted = false;
  }

  checkUpToDateVersion = () => {
    this.reloadInterval = setInterval(checkAppVersion, FREQUENCY.CHECK_VERSION);
    checkAppVersion();
  };

  handleFetchError = (e, url) => {
    if (navigator.onLine) {
      if (typeof e === 'string') {
        toast.error(e);
      }
      Logger.fetchError({ e, url, method: 'GET' });
    } else {
      this.showNetworkErrorToast();
    }
  };

  handleOrdersToggle = (
    payload: Partial<OrdersConfig>,
    successMessage: string
  ) => {
    return this.props.updateOrdersConfig(
      payload,
      this.props.restaurant as NormalizedRestaurant,
      () => toast.success(successMessage)
    );
  };

  updateOrderCustomer = (order: IOrder, customer: IOrder['customer']) => {
    const payload: Partial<IOrder> = { customer };
    const onSuccess = () => toast.success('Kurier został wezwany');
    const onError = () => toast.error('Wystąpił błąd podczas wzywania kuriera');
    this.props.updateOrder(payload, order.id, this.props.restaurant!.id, {
      onSuccess,
      onError,
    });
  };
  updateOrderStatus = (
    order: IOrder,
    status: string,
    extra?: Record<string, any>
  ) => {
    const onSuccess = () => this.props.closeOrderModal();
    if (status === ORDER_STATUS.DELIVERED) {
      return this.props.setModal(
        {
          title: 'Czy zamawiający na pewno otrzymał zamówienie?',
          text: `Nie oznaczaj zamówienia jako ${
            Order.isSelfCollect(order) ? 'odebrane' : 'dostarczone'
          } zanim zostanie ono przekazane klientowi.
          Może to być mylące dla zamawiających, a nie chcemy, aby się denerwowali :)`,
          confirm: () => {
            this.props.updateOrderStatus(order, status, this.props.restaurant, {
              extra,
              onSuccess,
            });
          },
          confirmColor: 'success',
          confirmText: 'Potwierdź',
          cancelText: 'Anuluj',
        },
        ModalTypes.CONFIRM
      );
    }
    this.props.updateOrderStatus(order, status, this.props.restaurant, {
      extra,
      onSuccess,
    });
  };

  dismissToastIfNeeded = () => {
    if (this.errorToastId) {
      toast.dismiss(this.errorToastId);
      this.errorToastId = null;
    }
  };

  showNetworkErrorToast() {
    if (this.errorToastId) {
      return;
    }
    const msg =
      'Błąd pobierania zamówień - brak dostępu do sieci. Odśwież stronę dla pobrania zamówień.';
    this.errorToastId = toast.error(msg, {
      autoClose: false,
    });
  }

  showGeneralToastError = () => {
    toast.error('Wystąpił problem. Odśwież stronę.', {
      autoClose: false,
    });
  };

  changeView = (view) => {
    this.setState({ view });
    PERSISTENT_LS.setOrdersView(view === VIEWS.GRID ? 'GRID' : 'LIST');
  };

  render() {
    const { bookingConfig, ordersConfig } = this.props;
    const restaurant = this.props.restaurant as NormalizedRestaurant;

    if (!isOrdersConfigFinished(ordersConfig, restaurant)) {
      return (
        <OrdersConfigNotFinished
          ordersConfig={ordersConfig}
          restaurant={restaurant}
        />
      );
    }

    return (
      <>
        <DeliveriesFetcher />
        <ReservationsFetcher
          config={bookingConfig}
          frequency={FREQUENCY.REFRESH_SECONDARY}
          includeTablesAndTheirPlaces={false}
          requireReservationsProduct
          restaurant={restaurant}
        />
        <PendingOrdersContainer />
        <UpdatesProvider
          restaurant={restaurant}
          target={UpdateTarget.OrdersConfig}
        />
        <DisableOrdersToggle
          ordersConfig={ordersConfig}
          onUpdate={this.handleOrdersToggle}
        >
          <PreviewLink
            className="offset-md-1 mt-2 mt-md-0"
            restaurant={restaurant}
          />
          <ViewToggler
            activeView={this.state.view}
            onViewChange={this.changeView}
          />
        </DisableOrdersToggle>
        <OrdersDeliveryTimeAlert
          restaurant={restaurant}
          ordersConfig={ordersConfig}
          setModal={this.props.setModal}
          updateOrdersConfig={this.props.updateOrdersConfig}
        />
        <OrdersFetcher
          frequency={FREQUENCY.REFRESH}
          ordersConfig={ordersConfig}
          onFetchError={this.handleFetchError}
          onFetchSuccess={this.dismissToastIfNeeded}
          showLastFetchTime
        />
        <RestaurantIsNotLiveAlert type="orders" restaurant={restaurant} />
        <CheckDeliveryPrice restaurant={restaurant} />
        <Row className="orders-container">{this.renderOrders()}</Row>
        {this.props.openedOrder && (
          <ModalContext.Consumer>
            {(modalBag) => (
              <ViewOrderModal
                order={this.props.openedOrder as IOrder}
                onClose={this.props.closeOrderModal}
                onUpdateOrderStatus={this.updateOrderStatus}
                setModal={modalBag.setModal}
                renderOrderSource={this.renderOrderSource}
                restaurant={restaurant}
                updatingOrders={this.props.updatingOrders}
              />
            )}
          </ModalContext.Consumer>
        )}
      </>
    );
  }

  renderOrderSource = (order: IOrder) => <OrderSource order={order} />;

  renderOrders() {
    const {
      orders,
      ordersLoading,
      restaurant,
      updatingOrders,
      userRestaurants,
    } = this.props;
    const isNotFinished = (order: IOrder) =>
      !isDelivered(order) && !isDeclined(order);
    const todaysOrdersToDisplay = orders
      .filter(isNotNew)
      .filter((order) => isToday(newSafeDate(order.created_at)));
    const yesterdaysOrdersToDisplay = orders
      .filter(isNotNew)
      .filter(isNotFinished)
      .filter((order) => !isToday(newSafeDate(order.created_at)));

    return (
      <>
        {ordersLoading && <OtoSpinner center />}
        <OrdersList
          orders={todaysOrdersToDisplay}
          ordersLoading={updatingOrders}
          addDelivery={this.props.addDelivery}
          handleOrderClick={this.props.openOrderModal}
          updateOrderCustomer={this.updateOrderCustomer}
          updateOrderStatus={this.updateOrderStatus}
          restaurant={restaurant as NormalizedRestaurant}
          view={this.state.view}
          userRestaurants={userRestaurants}
        />
        {yesterdaysOrdersToDisplay.length > 0 && (
          <>
            <h3 className="col-12 mb-3">Wczorajsze niedokończone zamówienia</h3>
            {yesterdaysOrdersToDisplay.map((order) => (
              <OrderCard
                key={order.id}
                order={order}
                isLoading={updatingOrders.includes(order.id)}
                onOrderClick={this.props.openOrderModal}
                onUpdateOrderStatus={this.updateOrderStatus}
                renderCourrierDetails={this.renderOrderCourrierDetails}
                restaurantName={
                  (
                    (userRestaurants || []).find(
                      (r) => r.id === order.restaurant_id
                    ) || {}
                  ).name
                }
                restaurant={restaurant as NormalizedRestaurant}
                view={this.state.view.text}
              />
            ))}
          </>
        )}
      </>
    );
  }

  renderOrderCourrierDetails = (order: IOrder) => {
    return (
      <ViewOrderCourrierDetails
        onDeliveryAdd={this.props.addDelivery}
        onUpdateOrderCustomer={this.updateOrderCustomer}
        order={order}
        restaurant={this.props.restaurant as NormalizedRestaurant}
      />
    );
  };
}

const mapStateToProps = (state: IAppState) => ({
  orders: selectAllOrders(state),
  ordersLoading: selectOrdersLoading(state),
  openedOrder: selectOpenedOrder(state),
  ordersConfig: selectOrdersConfig(state) as OrdersConfig,
  bookingConfig: selectBookingConfig(state),
  restaurant: selectRestaurant(state) as NormalizedRestaurant,
  updatingOrders: selectUpdatingOrders(state),
  userRestaurants: selectUserRestaurants(state),
});

const mapDispatchToProps = {
  addDelivery,
  closeOrderModal,
  openOrderModal,
  setOrdersSuccess,
  setOrdersError,
  updateOrder,
  updateOrderStatus,
  updateOrdersConfig,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const enhance = compose(withSetModal, connector);

type PropsFromRedux = ConnectedProps<typeof connector>;

const EnhancedOrdersListPage = enhance(OrdersListPage);
// @ts-ignore
EnhancedOrdersListPage.navName = 'Lista zamówień';
// @ts-ignore
EnhancedOrdersListPage.url = '/orders';

export default EnhancedOrdersListPage as React.Component & PageComponentProps;
