import type { Dispatch } from 'redux';
import { toast } from 'react-toastify';

import APIService, { ApiError } from 'services/api';
import { IDeliveryCreateData, IDelivery } from 'types/delivery';
import { NormalizedRestaurant } from 'types/restaurants';
import { getArrayWithoutObject, getArrayWithUpdatedObject } from 'utils/array';
import Logger from 'utils/log';
import { LOGOUT } from './app';

export interface IDeliveryReducerState {
  deliveries: IDelivery[];
  deliveriesLoading: boolean;
  deliveriesError: boolean;
  lastUpdateTime: Date | null;
}

export const INITIAL_STATE: IDeliveryReducerState = {
  deliveries: [],
  deliveriesLoading: false,
  deliveriesError: false,
  lastUpdateTime: null,
};

export const ADD_DELIVERY = 'ADD_DELIVERY';
export const SET_DELIVERIES_LOADING = 'SET_DELIVERIES_LOADING';
export const SET_DELIVERIES_SUCCESS = 'SET_DELIVERIES_SUCCESS';
export const SET_DELIVERIES_ERROR = 'SET_DELIVERIES_ERROR';

function deliveriesReducer(
  state = INITIAL_STATE,
  action: any = {}
): IDeliveryReducerState {
  switch (action.type) {
    case SET_DELIVERIES_LOADING:
      return {
        ...state,
        deliveriesLoading: true,
        deliveriesError: false,
      };
    case SET_DELIVERIES_SUCCESS:
      return {
        deliveries: action.deliveries,
        deliveriesLoading: false,
        deliveriesError: false,
        lastUpdateTime: new Date(),
      };
    case SET_DELIVERIES_ERROR:
      return {
        ...state,
        deliveriesLoading: false,
        deliveriesError: action.error,
      };
    case ADD_DELIVERY:
      return {
        ...state,
        deliveries: [...state.deliveries, action.newDelivery],
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export const setDeliveriesSuccess = (deliveries: IDelivery[]) => ({
  type: SET_DELIVERIES_SUCCESS,
  deliveries,
});
export const setDeliveriesError = (e) => ({
  type: SET_DELIVERIES_ERROR,
  error: e,
});

export const loadDeliveries = (url: string) => (dispatch) => {
  dispatch({ type: SET_DELIVERIES_LOADING });
  return APIService.get(url)
    .then((deliveries) => dispatch(setDeliveriesSuccess(deliveries)))
    .catch((e) => {
      Logger.fetchError({ e, url, method: 'GET' });
      toast.error('Problem pobierania listy dostaw');
      return dispatch(setDeliveriesError(e));
    });
};

export const addDelivery =
  (delivery: IDeliveryCreateData, restaurant: NormalizedRestaurant) =>
  (dispatch: Dispatch) => {
    return APIService.post(
      `/restaurants/${restaurant.id}/deliveries`,
      delivery
    ).then((newDelivery: IDelivery) => {
      dispatch({
        type: ADD_DELIVERY,
        newDelivery: newDelivery,
      });
      return newDelivery;
    });
  };

export const updateDelivery = (deliveries, delivery, payload) => (dispatch) => {
  const url = `/restaurants/${delivery.restaurant_id}/deliveries/${delivery.id}?include=restaurant,statusLogs`;
  return APIService.put(url, payload)
    .then((updatedDelivery) => {
      toast.success('Dostawa zaktualizowana');
      return dispatch(
        setDeliveriesSuccess(
          getArrayWithUpdatedObject(deliveries, updatedDelivery)
        )
      );
    })
    .catch((e) => {
      if (e instanceof ApiError && e.statusCode === 403) {
        toast.error(
          'Nie masz uprawnień do aktualizacji dostawy, odśwież listę dostaw'
        );
      } else {
        Logger.fetchError({ e, url, method: 'PUT', payload });
        toast.error('Błąd aktualizacji dostawy');
      }
      return dispatch(setDeliveriesError(e));
    });
};

export const deleteDelivery = (deliveries, delivery) => (dispatch) => {
  const url = `/restaurants/${delivery.restaurant_id}/deliveries/${delivery.id}`;
  return APIService.del(url)
    .then((deletedDelivery) => {
      toast.success('Dostawa wykasowana');
      return dispatch(
        setDeliveriesSuccess(getArrayWithoutObject(deliveries, deletedDelivery))
      );
    })
    .catch((e) => {
      Logger.fetchError({ e, url, method: 'DELETE' });
      toast.error('Błąd wykasowania dostawy');
      return dispatch(setDeliveriesError(e));
    });
};

export default deliveriesReducer;

type DeliveriesStateSlice = { deliveries: IDeliveryReducerState };

export const selectDeliveries = (state: DeliveriesStateSlice) =>
  state.deliveries.deliveries;
export const selectDeliveriesLoading = (state: DeliveriesStateSlice) =>
  state.deliveries.deliveriesLoading;
export const selectDeliveriesLastUpdateTime = (state: DeliveriesStateSlice) =>
  state.deliveries.lastUpdateTime;
