import React from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import differenceInMinutes from 'date-fns/differenceInMinutes';

import DriverDeliveriesList from 'app/components/deliveries/DriverDeliveriesList';
import DeliveriesFetcher from '../containers/DeliveriesFetcher';
import { selectUser, selectUserLoading, updateUser } from 'store/app';
import {
  loadDeliveries,
  selectDeliveries,
  selectDeliveriesLoading,
  updateDelivery,
} from 'store/deliveries';
import { updateOrder } from 'store/orders';
import { selectRestaurant } from 'store/restaurant';
import DeliveriesMap from 'app/components/deliveries/DeliveriesMap';
import { LOG_BAG } from 'utils/log';
import { isDelivered } from 'utils/deliveries';
import { canOrderDelivery } from 'utils/restaurant';
import OtoToggle from 'app/components/common/OtoToggle';
import OtoSpinner from 'app/components/common/OtoSpinner';
import UrlFetcher from '../containers/UrlFetcher';
import RobotsList from 'app/components/robots/RobotsList';
import type { IRobot, NormalizedRestaurant, User } from 'types';

type DriverDeliveriesPageProps = PropsFromRedux;

type DriverDeliveriesPageState = {
  activeTab: string;
};

const COMPONENT_NAME = 'DriverDeliveriesPage';

class DriverDeliveriesPage extends React.Component<
  DriverDeliveriesPageProps,
  DriverDeliveriesPageState
> {
  DELIVERIES_LIST_TAB = 'deliveries-list';
  DELIVERIES_MAP_TAB = 'deliveries-map';

  static displayName = COMPONENT_NAME;
  static getDerivedStateFromError =
    LOG_BAG.createDerivedStateFromErrorLogger(COMPONENT_NAME);

  tabs = [
    {
      title: i18n.t('deliveries-page.deliveries-list-tab'),
      type: this.DELIVERIES_LIST_TAB,
    },
    {
      title: i18n.t('deliveries-page.deliveries-map-tab'),
      type: this.DELIVERIES_MAP_TAB,
    },
  ];

  state = { activeTab: this.DELIVERIES_LIST_TAB };

  toggle = (tab) => this.setState({ activeTab: tab });

  handleDeliveryUpdate = (delivery, payload) =>
    this.props.updateDelivery(this.props.deliveries, delivery, payload);

  handleOrderPrepareTimeSet = (delivery, prepare_time) =>
    this.props.updateOrder(
      { prepare_time },
      delivery.order_id,
      delivery.restaurant_id
    );

  handleIsWorkingChange = (e) => {
    const newIsWorking = e.target.checked;
    return this.props
      .updateUser(this.props.user, { is_working: newIsWorking })
      .then(() =>
        toast.success(`${newIsWorking ? 'Rozpoczęto' : 'Zakończono'} pracę`)
      )
      .catch((error) => {
        LOG_BAG.logError('Driver is working update error', error);
        const action = newIsWorking ? 'rozpoczęcia' : 'zakończończenia';
        toast.error(`Wystąpił błąd podczas ${action} pracy`);
      });
  };

  render() {
    const { deliveries, deliveriesLoading, restaurant, user, userLoading } =
      this.props;

    const filteredDeliveries = deliveries.filter(
      (item) =>
        !isDelivered(item) ||
        (isDelivered(item) &&
          differenceInMinutes(new Date(), new Date(item.updated_at)) < 30)
    );

    return (
      <>
        <OtoToggle
          label={
            <>
              {userLoading && <OtoSpinner className="mr-2" />}
              {user.is_working ? 'Zakończ pracę' : 'Rozpocznij pracę'}
            </>
          }
          disabled={userLoading}
          onChange={this.handleIsWorkingChange}
          checked={user.is_working}
        />
        <Nav tabs>
          {this.tabs.map((item) =>
            this.renderTabNavLink(item.title, item.type)
          )}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId={this.DELIVERIES_LIST_TAB}>
            <DeliveriesFetcher showLastFetchTime />
            {canOrderDelivery(restaurant) && (
              <DriverDeliveriesList
                deliveries={deliveries}
                loading={deliveriesLoading}
                onDeliveryUpdate={this.handleDeliveryUpdate}
                onOrderOrderPrepareTimeSet={this.handleOrderPrepareTimeSet}
                user={user}
              />
            )}
          </TabPane>
          <TabPane tabId={this.DELIVERIES_MAP_TAB}>
            <DeliveriesMap
              deliveries={filteredDeliveries}
              restaurant={restaurant}
            />
          </TabPane>
        </TabContent>
        <UrlFetcher<IRobot[]> url={`/users/${user.id}/robots`}>
          {([robots]) =>
            !!robots &&
            robots.length > 0 && (
              <RobotsList
                className="mt-2"
                header="Moje roboty"
                robots={robots}
              />
            )
          }
        </UrlFetcher>
      </>
    );
  }

  renderTabNavLink(title, type) {
    return (
      <NavItem key={type}>
        <NavLink
          className={this.state.activeTab === type ? 'active' : ''}
          onClick={() => {
            this.toggle(type);
          }}
        >
          {title}
        </NavLink>
      </NavItem>
    );
  }
}

// @ts-expect-error @FIXME route class component type
DriverDeliveriesPage.url = '/driver-deliveries';
// @ts-expect-error @FIXME route class component type
DriverDeliveriesPage.navName = 'Moje dostawy';

const mapStateToProps = (state) => ({
  deliveries: selectDeliveries(state),
  deliveriesLoading: selectDeliveriesLoading(state),
  restaurant: selectRestaurant(state) as NormalizedRestaurant,
  userLoading: selectUserLoading(state),
  user: selectUser(state) as User,
});

const mapDispatchToProps = {
  loadDeliveries,
  updateDelivery,
  updateOrder,
  updateUser,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const ConnectedDriverDeliveriesPage = connector(DriverDeliveriesPage);

export default ConnectedDriverDeliveriesPage;
